<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card
            class="mt-5"
            :title="$t('Password reset')"
          >
            <b-form @reset="onReset" @submit="onSubmit" v-if="show">
              <b-row>
                <!--Password1-->
                <b-col md="12" sm="12">
                  <b-form-group
                    id="password1"
                    :label="$t('Password:')"
                    label-for="password1"
                  >
                    <b-form-input
                      id="password1"
                  :placeholder="$t('Enter password here')"
                      type="password"
                      v-model="password1"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class="form-text text-danger" v-if="!$v.password1.required">{{$t('Password is required')}}</small>
                    <small class="form-text text-danger" v-if="!$v.password1.minLength">{{$t('Password must have at least')}} {{$v.password1.$params.minLength.min}}
                      {{$t('letters.')}}
                    </small>
                  </div>
                </b-col>

                <!--Password1-->
                <b-col md="12" sm="12">
                  <b-form-group
                    id="password2"
                    :label="$t('Password:')"
                    label-for="password2"
                  >
                    <b-form-input
                      id="password2"
                      :placeholder="$t('Enter password again')"
                      type="password"
                      v-model="password2"
                    ></b-form-input>
                  </b-form-group>

                  <div class="" v-if="$v.$error">
                    <small class="form-text text-danger"
                           v-if="!$v.password2.sameAsPassword">{{$t('Repeat password must be identical.')}}</small>
                    <small class="form-text text-danger"
                           v-if="!$v.password2.required">{{$t('Repeat password must be identical.')}}</small>
                    <small class="form-text text-danger"
                           v-if="!$v.password2.minLength">{{$t('Repeated password must have at least')}} {{$v.password2.$params.minLength.min}} {{$t('letters.')}}
                    </small>
                  </div>
                </b-col>

                <small class="col-md-12 form-text text-muted">{{$t('Password should minimum six characters, at least one letter and one number.')}}</small>
              </b-row>

              <div v-if="error">
                <b-alert show variant="info">
                  {{error}}
                </b-alert>
              </div>

              <div v-if="success">
                <b-alert show variant="success">
                  {{success}}
                </b-alert>
              </div>

              <div class="mt-3">
                <b-button class="mr-3" type="submit" variant="primary">{{$t('Change Password')}}</b-button>
                <b-button type="reset" variant="danger">{{$t('Reset')}}</b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { AuthService } from '../../services/auth.service'
import { validationMixin } from 'vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import sameAs from 'vuelidate/lib/validators/sameAs'
import GenderAppFooter from './../../components/Footer'

export default {
  name: 'ResetPassword',
  data () {
    return {
      password1: '',
      password2: '',
      show: true,
      error: '',
      success: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    password1: {
      required,
      minLength: minLength(8)
    },
    password2: {
      required,
      sameAsPassword: sameAs('password1'),
      minLength: minLength(8)
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = 'Please check above error(s).'
      } else {
        const data = {
          password: this.password2,
          uid: this.$route.params.uid,
          token: this.$route.params.token
        }
        AuthService.passwordResetConfirm(data)
          .then((response) => {
            if (response.data.success) {
              this.success = response.data.status_message
            } else {
              this.error = response.data.status_message
            }
          }).then((response) => {
            this.$router.push({ name: 'home' })
            this.error = ''
          })
          .catch((error) => {
            this.error = error
            this.success = ''
          })
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.password1 = ''
      this.password2 = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  components: {
    TopNavBar,
    GenderAppFooter
  }
}
</script>

<style scoped>

</style>
